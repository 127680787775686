<template>
<div class="ball"></div>
</template>

<style lang="scss" scoped>
.ball {
  height: 8rem;
  width: 8rem;
  background: $pale_pink;
  border-radius: 50%;
}

.ball--medium {
  height: 11rem;
  width: 11rem;
}
.ball--big {
  height: 16rem;
  width: 16rem;
}

.ball--xbig {
  height: 22rem;
  width: 22rem;
}

.ball--outlined {
  background: transparent;
  border: 2px solid $pale_pink;
}

.ball--outline-white {
  background: transparent;
  border: 2px solid $white;
}

.ball--outline-red {
  background: transparent;
  border: 2px solid $ven_red;
}

.ball--purple {
  background: $violet_blue;
}
</style>
